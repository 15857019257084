import styled, { css } from 'styled-components'
import {
  fontCss,
  FontProps,
  lineHeightCss,
  mobileFontCss,
  mobileLineHeightCss,
} from '../../../../utils/styleUtilsNew'

type PriceAmountUiProps = {
  lineHeight: number
  mobileLineHeight?: number
} & FontProps

const PricePlanAmountUi = styled.div<PriceAmountUiProps>`
  //flex-basis: 60%;
  text-align: right;
  margin-left: auto;
  color: ${p => p.color};
  ${fontCss}
  ${lineHeightCss}
  ${p => p.theme.phone} {
    ${mobileFontCss}
    ${mobileLineHeightCss}
  }
  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobileFontCss}
      ${mobileLineHeightCss}
    `}
`

export default PricePlanAmountUi
