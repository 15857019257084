import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonContentTable from 'common/components/entities/ContentTable'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { ContentTableInterface } from 'common/types/entities/ContentTableInterface'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { OldTextEntityInterface } from 'common/types/entities/OldEntityInterface'
import useManagement from 'publisher/hooks/useManagement'
import { managementSelectors, usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'

function ContentTable({ entity }: { entity: ContentTableInterface }) {
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const headings = usePage(page =>
    pageSelectors.getOrderedByPositionHeadings(page, !isDesktop),
  )

  const globalFontFamily = usePage(pageSelectors.getGlobalTextFontFamily)
  const defaultState =
    typeof entity.isOpenedByDefault === 'boolean'
      ? !entity.isOpenedByDefault
      : false

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
        styles={entity.margin}
        mobileStyles={entity.mobileMargin}
      />
    )
  }

  const processedHeadlines = headings
    .map((heading: HeadlineInterface | OldTextEntityInterface) => {
      const contentRawState = JSON.parse(
        'rawContentState' in heading
          ? heading.rawContentState
          : heading.options.rawContentState,
      )

      const content = contentRawState.blocks[0].text

      const headlineType =
        Object.values(HeadingTypeEnum).find(
          headingType => contentRawState.blocks[0].type === headingType,
        ) || HeadingTypeEnum.HeaderFive

      return {
        headline: heading,
        headlineType,
        content,
      }
    })
    .filter(processedHeadline =>
      entity.headersTypes.includes(processedHeadline.headlineType),
    )

  return (
    <StyleWrapper
      styles={entity.margin}
      mobileStyles={entity.mobileMargin}
      desktop={entity.appearance.desktop}
      mobile={entity.appearance.mobile}
    >
      <CommonContentTable
        iconClassName={entity.iconClassName}
        closedIconClassName={
          entity.closedIconClassName && entity.closedIconClassName
        }
        defaultState={defaultState}
        iconColor={entity.iconColor}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        processedHeadlines={processedHeadlines}
        headerFontFamily={entity.headerFontFamily || globalFontFamily}
        headerFontStyle={entity.headerFontStyle}
        headerFontWeight={entity.headerFontWeight}
        contentColor={entity.contentColor}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        headerTitle={entity.headerTitle}
        headerFontSize={entity.headerFontSize}
        headerColor={entity.headerColor}
        contentFontSize={entity.contentFontSize}
        contentFontFamily={entity.contentFontFamily}
        contentFontStyle={entity.contentFontStyle}
        contentFontWeight={entity.contentFontWeight}
        contentItemGap={entity.contentItemGap}
      />
    </StyleWrapper>
  )
}

export default ContentTable
