import { useDispatch } from 'react-redux'
import React from 'react'
import CommonOfferPrice from 'common/components/entities/OfferPriceNew'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { Coupon as CouponType } from 'common/types/CouponType'
import { PricePlan } from 'common/types/OfferInterface'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { getNetPrice, getPricePlanAmount } from 'common/utils/priceCalculator'
import { getProductBumpInfo, getProductInfo } from 'common/utils/productUtils'
import { getVat } from 'common/utils/taxCalculator'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import {
  managementSelectors,
  useManagement,
  useOptIn,
  usePage,
  usePayment,
} from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import { setActivePricePlanId } from 'publisher/store/payment/paymentActions'
import paymentSelectors, {
  getActivePaymentMethod,
  getCheckedCoupon,
  getCheckedPricePlanId,
  getOfferOwnerCountry,
  getPricePlans,
  isOnlyPersonalAvailable,
  getCheckedBump,
} from 'publisher/store/payment/paymentSelectors'
import pageSelectors from '../../../store/page/pageSelectors'

function OfferPrice({ entity }: { entity: OfferPriceInterface }) {
  const dispatch = useDispatch()
  const locale = usePage(pageSelectors.getLocale)
  const pricePlans = usePayment(getPricePlans)
  const coupon = usePayment(getCheckedCoupon)
  const activePlanId = usePayment(getCheckedPricePlanId)
  const bump = usePayment(getCheckedBump)
  const offerOwnerCountry = usePayment(getOfferOwnerCountry)
  const activePaymentMethod = usePayment(getActivePaymentMethod)
  const isVatNotChargeable =
    usePayment(isOnlyPersonalAvailable) ||
    activePaymentMethod === PaymentMethodEnum.RazorpayCard
  const taxNumber = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.TaxNumber),
  )
  const customerCountry = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.Country),
  )

  const isDesktop = useManagement(managementSelectors.isDesktop)

  const productElements = usePage(p =>
    pageSelectors.getVisibleProductElement(p, isDesktop),
  )

  const product = usePayment(paymentSelectors.getProduct)

  const activeProductVariant = usePayment(
    paymentSelectors.getProductActiveVariant,
  )

  const productQuantity = usePayment(paymentSelectors.getProductQuantity)

  const bumpPlan = bump?.pricePlans[0]
  const productBump = bump?.product || null

  const productVat = getVat(
    offerOwnerCountry,
    customerCountry,
    taxNumber,
    product,
  )

  const productBumpVat = getVat(
    offerOwnerCountry,
    customerCountry,
    taxNumber,
    productBump,
  )

  const calculatedProductInfo = getProductInfo(
    productElements,
    product,
    activeProductVariant,
    productQuantity,
    isVatNotChargeable,
    productVat,
    locale,
    coupon,
  )
  const calculatedProductBumpInfo = getProductBumpInfo(
    productBump,
    isVatNotChargeable,
    productBumpVat,
    locale
  )

  const vat = getVat(offerOwnerCountry, customerCountry, taxNumber)

  function getPricePlanTax(
    pricePlan: PricePlan,
    vat: number,
    coupon?: CouponType,
  ) {
    const planAmount = getPricePlanAmount(pricePlan)
    const price = coupon ? getNetPrice(planAmount, 1, coupon) : planAmount

    return Math.round((price * vat) / 100)
  }

  function togglePricePlanId(pricePlanId: number) {
    dispatch(setActivePricePlanId(pricePlanId))
  }

  // const calculatedCheckedPlanId =
  //   (isPreview || isScreenshot) && !checkedPlanId
  //     ? calculatedPricePlans[0] && calculatedPricePlans[0].id
  //     : checkedPlanId

  const activePricePlan = pricePlans.find(
    plan => plan.id === activePlanId,
  ) as PricePlan // todo

  return (
    <StyleWrapper
      margin={entity.margin}
      appearance={entity.appearance}
      mobileMargin={entity.mobileMargin}
    >
      <CommonOfferPrice
        attrId={entity.htmlAttrId}
        plans={pricePlans}
        activePlanId={activePlanId}
        bumpPlan={bumpPlan}
        coupon={coupon}
        tax={
          isVatNotChargeable ? 0 : getPricePlanTax(activePricePlan, vat, coupon)
        }
        bumpTax={
          isVatNotChargeable || !bumpPlan ? 0 : getPricePlanTax(bumpPlan, vat)
        }
        isVatNotChargeable={isVatNotChargeable}
        togglePricePlan={togglePricePlanId}
        customDescription={entity.customDescription}
        productInfo={calculatedProductInfo}
        productBumpInfo={calculatedProductBumpInfo}
        locale={locale}
        nameFontSize={entity.nameFontSize}
        mobileNameFontSize={entity.mobileNameFontSize}
        nameFontStyle={entity.nameFontStyle}
        mobileNameFontStyle={entity.mobileNameFontStyle}
        nameFontWeight={entity.nameFontWeight}
        mobileNameFontWeight={entity.mobileNameFontWeight}
        nameFontFamily={entity.nameFontFamily}
        mobileNameFontFamily={entity.mobileNameFontFamily}
        nameLineHeight={entity.nameLineHeight}
        mobileNameLineHeight={entity.mobileNameLineHeight}
        nameColor={entity.nameColor}
        mobileNameColor={entity.mobileNameColor}
        amountFontSize={entity.amountFontSize}
        mobileAmountFontSize={entity.mobileAmountFontSize}
        amountFontStyle={entity.amountFontStyle}
        mobileAmountFontStyle={entity.mobileAmountFontStyle}
        amountFontWeight={entity.amountFontWeight}
        mobileAmountFontWeight={entity.mobileAmountFontWeight}
        amountFontFamily={entity.amountFontFamily}
        mobileAmountFontFamily={entity.mobileAmountFontFamily}
        amountLineHeight={entity.amountLineHeight}
        mobileAmountLineHeight={entity.mobileAmountLineHeight}
        amountColor={entity.amountColor}
        mobileAmountColor={entity.mobileAmountColor}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionFontWeight={entity.descriptionFontWeight}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
      />
    </StyleWrapper>
  )
}

export default OfferPrice
