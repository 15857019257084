import { ProductVariantInterface } from 'common/types/entities/ProductInterface'
import {
  DECREASE_PRODUCT_QUANTITY,
  INCREASE_PRODUCT_QUANTITY,
  INITIALIZE_PRODUCT_QUANTITY,
  SET_ACTIVE_PRICE_PLAN_ID,
  SET_ACTIVE_PRODUCT_VARIANT,
  SET_DLOCAL_DOCUMENT_NUMBER,
} from './paymentActionTypes'

export function setDlocalDocumentNumber(value: string) {
  return {
    payload: value,
    type: SET_DLOCAL_DOCUMENT_NUMBER,
  }
}

export function setActivePricePlanId(pricePlanId: number) {
  return {
    payload: pricePlanId,
    type: SET_ACTIVE_PRICE_PLAN_ID,
  }
}

export function initializeProductQuantity(quantity: number) {
  return {
    payload: quantity,
    type: INITIALIZE_PRODUCT_QUANTITY,
  }
}

export function increaseProductQuantity() {
  return {
    type: INCREASE_PRODUCT_QUANTITY,
  }
}

export function decreaseProductQuantity() {
  return {
    type: DECREASE_PRODUCT_QUANTITY,
  }
}

export function setProductActiveVariant(
  productVariant: ProductVariantInterface,
) {
  return {
    type: SET_ACTIVE_PRODUCT_VARIANT,
    payload: productVariant,
  }
}
