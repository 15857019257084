import { useRollbar } from '@rollbar/react'
import React, { useEffect, useState, memo } from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import * as api from 'publisher/api/paypal'
import FieldErrors from 'publisher/components/FieldErrors'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import loadScript from 'publisher/utils/loadScript'
import useUpsellSubmit from '../hooks/useUpsellSubmit'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

function PaypalUpsellButton({ entity }: UpsellPaymentButtonPropsType) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const [isScriptLoading, setScriptLoading] = useState(true)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)

  useEffect(() => {
    loadScript(
      'https://www.paypalobjects.com/api/checkout.min.js',
      'paypal-js',
      () => setScriptLoading(false),
    )
  }, [])

  const handleSubmit = async () => {
    await submit(async (body: any) => {
      try {
        // @ts-ignore
        paypal.config.env = process.env.PAYPAL_ENV
        // @ts-ignore
        paypal.checkout.initXO()
        const { data } = await api.purchaseDetails(
          pageId,
          body,
          purchaseProcessId,
        )
        // @ts-ignore
        paypal.checkout.startFlow(data.approvalUrl)
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.error(`Paypal upsell failed`, error as Error)
        }

        // @ts-ignore
        paypal.checkout.closeFlow()
      }
    })
  }

  return (
    <React.Fragment>
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonButton
          onClick={handleSubmit}
          disabled={isLoading || isScriptLoading}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          subTextColor={entity.subTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

export default memo(PaypalUpsellButton)
