import PropTypes from 'prop-types'
import React from 'react'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import { POPUP_CONTENT_ID } from 'client/constants/editorIds'
import useManagement, { selectors as managementSelectors } from 'publisher/hooks/useManagement'
import PopupClose from './ui/PopupClose'
import PopupContent from './ui/PopupContent'
import PopupDialog from './ui/PopupDialog'
import PopupScrollContentUi from './ui/PopupScrollContentUi'

const Popup = ({
  showClose,
  onCloseHandler,
  children,
  styles,
  mobileStyles,
  overflow,
  maxHeight,
  attrId,
}) => {
  const { width, padding, ...restStyles } = styles
  const isMobile = useManagement(managementSelectors.isMobile)

  const { padding: mobilePadding, ...restMobileStyles } = mobileStyles
  return (
    <PopupDialog
      styles={{
        ...restStyles,
        maxHeight,
        width: isMobile ? 'auto' : width || '600px',
      }}
      mobileStyles={restMobileStyles}
      overflow={overflow}
      id={attrId}
    >
      <PopupScrollContentUi
        styles={{ padding }}
        mobileStyles={{ padding: mobilePadding }}
        id={POPUP_CONTENT_ID}
      >
        {Boolean(Number(showClose)) && (
          <PopupClose color={styles.color} close={onCloseHandler} />
        )}
        <PopupContent>{children}</PopupContent>
      </PopupScrollContentUi>
    </PopupDialog>
  )
}

Popup.propTypes = {
  attrId: PropTypes.string,
  children: PropTypes.node.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.any).isRequired,
  mobileStyles: PropTypes.objectOf(PropTypes.any).isRequired,
  maxHeight: PropTypes.string,
  overflow: PropTypes.string,
}

Popup.defaultProps = {
  showClose: false,
  overflow: 'visible',
  maxHeight: 'calc(100vh - 40px)',
}

export default provideMobileStyles(Popup)
