import PropTypes from 'prop-types'
import React, { memo } from 'react'
import usePaypalPaymentHandler from 'publisher/hooks/usePaypalPaymentHandler'
import { OldEntityInterface } from '../../../../common/types/entities/OldEntityInterface'
import FieldErrors from '../../FieldErrors'
import BaseButton from '../BaseButton'

function PaypalPaymentButton({ entity }: { entity: OldEntityInterface }) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const { errors, isLoading, handleSubmit, isScriptLoading } =
    usePaypalPaymentHandler(entity)

  return (
    <React.Fragment>
      <BaseButton
        handleClick={handleSubmit}
        disabled={isLoading || isScriptLoading}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        loading={isLoading}
        loadingText={options.loadingText}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
      <FieldErrors errors={errors} align="center" />
    </React.Fragment>
  )
}

PaypalPaymentButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      attrId: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      borderType: PropTypes.string,
      loadingText: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }),
      subText: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }).isRequired,
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default memo(PaypalPaymentButton)
