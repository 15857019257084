import {
  getPricePlanAmount,
  getPriceWithCoupon,
} from 'common/utils/priceCalculator'
import { usePayment } from 'publisher/store'
import {
  getCheckedCoupon,
  getActivePricePlan,
  isOnlyPersonalAvailable,
} from 'publisher/store/payment/paymentSelectors'
import { useVat } from './useVat'

export function useFinalDigitalProductPrice() {
  const coupon = usePayment(getCheckedCoupon)
  const isVatNotChargeable = usePayment(isOnlyPersonalAvailable)
  const vat = useVat()
  const pricePlan = usePayment(getActivePricePlan)
  const pricePlanAmount = pricePlan ? getPricePlanAmount(pricePlan) : 0

  if (!pricePlan) {
    return null
  }

  return getPriceWithCoupon(
    pricePlanAmount,
    coupon,
    1,
    isVatNotChargeable ? 0 : vat,
  )
}
