import React from 'react'
import { useTranslation } from 'react-i18next'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { PricePlan as PricePlanType } from '../../../types/OfferInterface'
import { ReadablePricePlanAmount } from './PricePlan'
import PricePlanAmountUi from './ui/PricePlanAmountUi'
import PricePlanDescriptionUi from './ui/PricePlanDescriptionUi'
import PricePlanItemUi from './ui/PricePlanItemUi'

function getPlanWithTaxAsAmount(
  pricePlan: PricePlanType,
  tax: number,
  quantity: number,
) {
  const pricePlanWithTax: PricePlanType = { ...pricePlan }

  switch (pricePlanWithTax.type) {
    case PricePlanTypeEnum.OneShot:
      pricePlanWithTax.directChargeAmount = tax * quantity
      break
    case PricePlanTypeEnum.LimitedSubscription:
    case PricePlanTypeEnum.Subscription:
      pricePlanWithTax.subscriptionPlan = {
        ...pricePlanWithTax.subscriptionPlan,
      }
      pricePlanWithTax.subscriptionPlan.amount = tax * quantity
      break
    default:
      return pricePlanWithTax
  }
  return pricePlanWithTax
}

type VatProps = {
  plan: PricePlanType
  quantity: number
  tax: number
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

function Vat({
  plan,
  quantity,
  tax,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: VatProps) {
  const { t } = useTranslation(['common', 'publisher'])
  return (
    <PricePlanItemUi>
      <PricePlanDescriptionUi
        fontSize={descriptionFontSize}
        mobileFontSize={mobileDescriptionFontSize}
        fontStyle={descriptionFontStyle}
        mobileFontStyle={mobileDescriptionFontStyle}
        fontWeight={descriptionFontWeight}
        mobileFontWeight={mobileDescriptionFontWeight}
        fontFamily={descriptionFontFamily}
        mobileFontFamily={mobileDescriptionFontFamily}
        lineHeight={descriptionLineHeight}
        mobileLineHeight={mobileDescriptionLineHeight}
        color={descriptionColor}
        mobileColor={mobileDescriptionColor}
        data-test-id={`offer-price-bump-vat-${plan.id}`}
      >
        {t('components.offer_price.vat')}
      </PricePlanDescriptionUi>
      <PricePlanAmountUi
        fontSize={amountFontSize}
        mobileFontSize={mobileAmountFontSize}
        fontStyle={amountFontStyle}
        mobileFontStyle={mobileAmountFontStyle}
        fontWeight={amountFontWeight}
        mobileFontWeight={mobileAmountFontWeight}
        fontFamily={amountFontFamily}
        mobileFontFamily={mobileAmountFontFamily}
        lineHeight={amountLineHeight}
        mobileLineHeight={mobileAmountLineHeight}
        color={amountColor}
        mobileColor={mobileAmountColor}
      >
        <ReadablePricePlanAmount
          plan={getPlanWithTaxAsAmount(plan, tax, quantity)}
          locale={locale}
        />
      </PricePlanAmountUi>
    </PricePlanItemUi>
  )
}

export default Vat
