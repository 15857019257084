import { slugs } from 'common/constants/inputTypes'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import * as entityUtils from '../../tools/utils/entityUtils'
import getRootEntityTypeByPageType from '../utils/getRootEntityTypeByPageType'

export const UPDATE_ENTITY = 'UPDATE_ENTITY'

const defaultReducerState = {
  entities: {},
}

export default function (state = defaultReducerState, action) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_ENTITY:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload,
        },
      }
    default:
      return state
  }
}

export const getAllDescendants = (state, nodeId) => {
  if (!state.entities[nodeId] || !state.entities[nodeId].childIds) {
    return []
  }

  return state.entities[nodeId].childIds.reduce(
    (prev, childId) => [
      ...prev,
      state.entities[childId],
      ...getAllDescendants(state, childId),
    ],
    [],
  )
}

export const getChildrenEntities = (state, childIds) =>
  childIds.map(childId => state.entities[childId]).filter(Boolean)

export const getByType = ({ entities }, type) =>
  Object.values(entities).filter(entity => entity.type === type)

export const getEntityByCondition = ({ entities }, predicate) =>
  Object.values(entities).find(predicate)

export const getRootEntity = ({ entities, type, id }) => {
  const entityType = getRootEntityTypeByPageType(type)
  const rootEntity = Object.values(entities).find(
    entity => entity.type === entityType,
  )
  if (!rootEntity) {
    throw new Error(
      `Root entity not found! Page id ${id} type ${type}, ${entityType}`,
    )
  }

  return rootEntity
}

export const getLocale = ({ locale }) => locale

export const getCountryFieldId = page => {
  const countryInput = getEntityByCondition(
    page,
    entity =>
      entity.type === structureTypes.FORM_INPUT &&
      entity.options.slug === slugs.COUNTRY,
  )

  return (countryInput && countryInput.options.slug) || null
}

export const getFirstOnloadPopup = ({ entities }) =>
  Object.values(entities).find(
    entity => entity.options && entity.options.openAutomatically === '1',
  )

export const getOnloadPopups = ({ entities }) =>
  Object.values(entities).filter(
    entity => entity.options && entity.options.openAutomatically === '1',
  )

export const getMobileOnloadPopups = ({ entities }) =>
  Object.values(entities).filter(
    entity =>
      entity.options && entity.options.openOnMobileAutomatically === '1',
  )

export const getFirstMobileOnloadPopup = ({ entities }) =>
  Object.values(entities).find(
    entity =>
      entity.options && entity.options.openOnMobileAutomatically === '1',
  )

export const getFirstOnExitPopup = ({ entities }) =>
  Object.values(entities).find(
    entity => entity.options && entity.options.openOnExit === '1',
  )

export const getEntityById = ({ entities }, entityId) =>
  (!!entities[entityId] && entities[entityId]) || null

export const getPageId = ({ id }) => id
// todo move isPreview mode to management
export const isPreviewMode = ({ previewMode }) => Boolean(previewMode)

export const isTemplate = ({ isTemplate }) => isTemplate

export const getPageType = ({ type }) => type

export const getExplicitConsents = ({ entities }) =>
  Object.values(entities).filter(
    entity =>
      entity.type === structureTypes.EXPLICIT_CONSENT ||
      entity.type === EntityTypeEnum.Checkbox,
  )

export const getVisibleExplicitConsents = (
  { entities },
  isDesktop,
  popupId,
) => {
  return Object.values(entities)
    .filter(
      e =>
        e.type === structureTypes.EXPLICIT_CONSENT ||
        e.type === EntityTypeEnum.Checkbox,
    )
    .filter(
      e =>
        entityUtils.isVisible(e, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(e, entities, isDesktop),
    )
    .filter(e =>
      popupId
        ? entityUtils.isPopupAscendantOfEntity(entities[popupId], e, entities)
        : entityUtils.isNotBelongsToPopups(e, entities),
    )
}

export const hasAllVisibleAscendants = (state, entity, isDesktop) =>
  entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop)

export const getExplicitConsentsIds = state =>
  getExplicitConsents(state).map(entity => entity.id)

export const getSeo = ({ seo }) => seo || {}

export const getId = ({ id }) => id

export const getGlobalSettings = ({ globalSettings }) => globalSettings || {}

export const getGlobalTextFontSize = ({ globalSettings }) =>
  globalSettings ? globalSettings.textFontSize : null

export const getGlobalMobileTextFontSize = ({ globalSettings }) =>
  globalSettings ? globalSettings.mobileTextFontSize : null

export const getGlobalTextLineHeight = ({ globalSettings }) =>
  globalSettings ? globalSettings.textLineHeight : null

export const getGlobalMobileTextLineHeight = ({ globalSettings }) =>
  globalSettings ? globalSettings.mobileTextLineHeight : null

export const getGlobalHeadingFontSize = ({ globalSettings }) =>
  globalSettings ? globalSettings.headingFontSize : null

export const getGlobalLinkColor = ({ globalSettings }) =>
  globalSettings ? globalSettings.linkColor : null

export const getGlobalTextColor = ({ globalSettings }) =>
  globalSettings ? globalSettings.textColor : null

export const getGlobalFontFamily = state =>
  state.globalSettings ? state.globalSettings.fontFamily : null

export function getEntityParents(entities, targetEntity) {
  return Object.values(entities)
    .filter(entity => entity.id === targetEntity.id)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
        ...(cur.parentId && entities[cur.parentId]
          ? getEntityParents(entities, entities[cur.parentId])
          : null),
      }),
      {},
    )
}

export function getEntityAncestorByType(entities, targetEntity, type) {
  const parents = getEntityParents(entities, targetEntity)
  return Object.values(parents).find(entity => entity.type === type)
}

export function getEntityAncestorsByType(entities, targetEntity, type) {
  const parents = getEntityParents(entities, targetEntity)
  return Object.values(parents).filter(entity => entity.type === type)
}

export const getAscendantPopupId = ({ entities }, entity) => {
  const popup = getEntityAncestorByType(entities, entity, structureTypes.POPUP)

  return popup ? popup.id : null
}

export const getAscendantPopup = ({ entities }, entity) => {
  return getEntityAncestorByType(entities, entity, structureTypes.POPUP)
}

export const getAscendant = ({ entities, type }, entity) => {
  const popup = getEntityAncestorByType(entities, entity, structureTypes.POPUP)

  if (popup) {
    return popup
  }
  // todo check blog_home page type on save
  return getEntityAncestorByType(
    entities,
    entity,
    getRootEntityTypeByPageType(type),
  )
}

export const getAscendantExplicitConsents = ({ entities }, entity) => {
  return getEntityAncestorsByType(
    entities,
    entity,
    structureTypes.EXPLICIT_CONSENT,
  ).concat(getEntityAncestorsByType(entities, entity, EntityTypeEnum.Checkbox))
}

export const getCompanyNameField = state => {
  return getEntityByCondition(
    state,
    entity =>
      entity.type === structureTypes.FORM_INPUT &&
      entity.options.slug === slugs.COMPANY_NAME,
  )
}

export const getTaxNumberFieldEntity = state => {
  return getEntityByCondition(
    state,
    entity =>
      entity.type === structureTypes.FORM_INPUT &&
      entity.options.slug === slugs.TAX_NUMBER,
  )
}

export const findEntityDescendantsFieldsSlugs = (state, entity) => {
  return getAllDescendants(state, entity.id)
    .filter(Boolean)
    .filter(e => e.type === structureTypes.FORM_INPUT)
    .map(e => e.options.slug)
}

export const findEntityDescendantsCaptcha = (state, entity) => {
  return getAllDescendants(state, entity.id)
    .filter(e => {
      if (typeof e === 'undefined') {
        console.log(`--e--`, e)
      }
    })
    .filter(e => e.type === EntityTypeEnum.Recaptcha)
    .filter(Boolean)
}

export const findEntityDescendantsExplicitConsents = (state, entity) => {
  if (!entity) {
    return []
  }
  return getAllDescendants(state, entity.id).filter(
    e =>
      e.type === structureTypes.EXPLICIT_CONSENT ||
      e.type === EntityTypeEnum.Checkbox,
  )
}

export const getVisibleOptInFields = (
  { entities },
  isDesktop,
  popupId,
  fields,
) => {
  return Object.values(entities)
    .filter(e => e.type === structureTypes.FORM_INPUT)
    .filter(e => e.options.slug !== null)
    .filter(
      e =>
        entityUtils.isVisible(e, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(e, entities, isDesktop),
    )
    .filter(e =>
      popupId
        ? entityUtils.isPopupAscendantOfEntity(entities[popupId], e, entities)
        : entityUtils.isNotBelongsToPopups(e, entities),
    )
    .reduce((acc, e) => {
      acc[e.options.slug] = fields[e.options.slug]
      return acc
    }, {})
}

export const selectors = {
  getChildrenEntities,
  getRootEntity,
  getSeo,
  getPageType,
  getByType,
  getAscendant,
  getEntityById,
  findEntityDescendantsFieldsSlugs,
  findEntityDescendantsExplicitConsents,
  getPageId,
  isTemplate,
  getGlobalFontFamily,
  getAscendantPopupId,
  getAscendantExplicitConsents,
  getExplicitConsents,
  getVisibleExplicitConsents,
  getExplicitConsentsIds,
  getLocale,
  hasAllVisibleAscendants,
  getCompanyNameField,
  getCompanyVatField: getTaxNumberFieldEntity,
  getOnloadPopups,
  getMobileOnloadPopups,
  getFirstOnExitPopup,
  getCountryFieldId,
  getGlobalLinkColor,
  getGlobalTextColor,
  getGlobalTextFontSize,
  getGlobalMobileTextFontSize,
  getGlobalTextLineHeight,
  getGlobalMobileTextLineHeight,
  findEntityDescendantsCaptcha,
  getAscendantPopup,
  getVisibleOptInFields,
}
