import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { EntityType } from 'common/types/Page'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import {
  OldEntityInterface,
  OldFieldEntityInterface,
  OldTextEntityInterface,
} from 'common/types/entities/OldEntityInterface'
import * as entityUtils from 'common/utils/entityUtils'
import { getEntityAncestorByType } from 'common/utils/entityUtils'
import { FieldSlugEnum } from '../../../common/enums/FieldSlugEnum'
import getRootEntityTypeByPageType from '../../utils/getRootEntityTypeByPageType'
import { PageState } from './PageStateInterface'

function getLocale(pageState: PageState) {
  return pageState.locale
}

function getGlobalLinkColor(state: PageState) {
  return state.globalSettings.linkColor
}

function getGlobalTextColor(state: PageState) {
  return state.globalSettings.textColor
}

function getGlobalTextFontFamily(state: PageState) {
  if (state.globalSettings.fontFamily) {
    return state.globalSettings.fontFamily
  }
}

function getGlobalTextFontSize(state: PageState) {
  if (state.globalSettings.textFontSize) {
    return parseInt(state.globalSettings.textFontSize)
  }
}

export function getTaxNumberFieldEntity(state: PageState) {
  return Object.values(state.entities).find(
    entity =>
      entity.type === structureTypes.FORM_INPUT &&
      (entity as OldEntityInterface).options.slug === FieldSlugEnum.TaxNumber,
  )
}

export function getCountryTypeEntity(state: PageState) {
  return Object.values(state.entities).find(
    entity =>
      entity.type === structureTypes.FORM_INPUT &&
      (entity as OldEntityInterface).options.slug === FieldSlugEnum.Country,
  )
}

export const getChildrenEntities = (state: PageState, childIds: string[]) =>
  childIds.map(childId => state.entities[childId]).filter(Boolean)

export function getCustomerTypeEntity(state: PageState) {
  return Object.values(state.entities).find(
    entity => entity.type === structureTypes.CUSTOMER_TYPE,
  )
}

function getGlobalMobileTextFontSize(state: PageState) {
  if (state.globalSettings.mobileTextFontSize) {
    return parseInt(state.globalSettings.mobileTextFontSize)
  }
}

function getGlobalTextLineHeight(state: PageState) {
  if (state.globalSettings.textLineHeight) {
    return parseInt(state.globalSettings.textLineHeight)
  }
}

function getGlobalMobileTextLineHeight(state: PageState) {
  if (state.globalSettings.mobileTextLineHeight) {
    return parseInt(state.globalSettings.mobileTextLineHeight)
  }
}

function getOrderedByPositionHeadings(state: PageState, isMobile: boolean) {
  return entityUtils.getOrderedByPositionHeadings(
    state.entities,
    isMobile,
    state.type,
  ) as OldTextEntityInterface[]
}

function getGlobalHeadingColor(state: PageState) {
  return state.globalSettings.headingColor
}

function getGlobalHeadingFontFamily(state: PageState) {
  if (state.globalSettings.headingFontFamily) {
    return state.globalSettings.headingFontFamily
  }
}

function getGlobalFontProperties({ globalSettings }: PageState) {
  return {
    fontFamily: globalSettings.fontFamily,
    fontStyle: globalSettings.fontStyle,
    fontWeight: globalSettings.fontWeight,
    headingFontFamily: globalSettings.headingFontFamily,
    headingFontStyle: globalSettings.headingFontStyle,
    headingFontWeight: globalSettings.headingFontWeight,
  }
}

function getSeo(pageState: PageState) {
  return pageState.seo
}

function getPageType(state: PageState) {
  return state.type
}

function getRootEntity(state: PageState) {
  const entityType = getRootEntityTypeByPageType(state.type)
  return Object.values(state.entities).find(
    entity => entity.type === entityType,
  )
}

function hasAllVisibleAscendants(
  state: PageState,
  entity: OldEntityInterface | EntityInterface,
  isDesktop: boolean,
) {
  return entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop)
}

export function getPageId(state: PageState) {
  return state.id
}

function getAscendantPopupId(
  state: PageState,
  entity: OldEntityInterface | EntityInterface,
) {
  const popup = getEntityAncestorByType(
    state.entities,
    entity,
    structureTypes.POPUP,
  )

  return popup ? popup.id : null
}

function getVisibleCheckboxes(
  state: PageState,
  isDesktop: boolean,
  popupId?: string | null,
) {
  return Object.values(state.entities)
    .filter(
      entity =>
        entity.type === EntityTypeEnum.Checkbox ||
        entity.type === structureTypes.EXPLICIT_CONSENT,
    )
    .filter(
      entity =>
        entityUtils.isVisible(entity, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
    .filter(entity =>
      popupId
        ? entityUtils.isPopupAscendantOfEntity(
            state.entities[popupId],
            entity,
            state.entities,
          )
        : entityUtils.isNotBelongsToPopups(entity, state.entities),
    )
}

export const getVisibleMandatoryCheckboxes = (
  state: PageState,
  isDesktop: boolean,
  popupId?: string | null,
): (CheckboxInterface | OldEntityInterface)[] => {
  return Object.values(state.entities)
    .filter(
      (entity): entity is CheckboxInterface | OldEntityInterface =>
        entity.type === EntityTypeEnum.Checkbox ||
        entity.type === structureTypes.EXPLICIT_CONSENT,
    )
    .filter(
      entity =>
        entityUtils.isVisible(entity, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
    .filter(entity =>
      popupId
        ? entityUtils.isPopupAscendantOfEntity(
            state.entities[popupId],
            entity,
            state.entities,
          )
        : entityUtils.isNotBelongsToPopups(entity, state.entities),
    )
    .filter(entity =>
      'options' in entity ? !entity.options?.optional : !entity?.optional,
    ) as CheckboxInterface[]
}

function getVisibleProductElement(state: PageState, isDesktop: boolean) {
  return Object.values(state.entities)
    .filter(entity => entity.type === EntityTypeEnum.Product)
    .filter(
      entity =>
        entityUtils.isVisible(entity, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
}

function getVisibleTwoStepPaymentFormOptInStep(
  state: PageState,
  isDesktop: boolean,
) {
  return Object.values(state.entities)
    .filter(
      entity =>
        entity.type === structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN,
    )
    .filter(
      entity =>
        entityUtils.isVisible(entity, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
}

export const getVisibleOptInFieldProperties = (
  state: PageState,
  isDesktop: boolean,
  fields: Record<string, string>,
  popupId?: string | null,
) => {
  return Object.values(state.entities)
    .filter(entity => entity.type === structureTypes.FORM_INPUT)
    .filter(
      entity =>
        (entity as OldFieldEntityInterface).options.slug !== null &&
        (entity as OldFieldEntityInterface).options.slug !== undefined,
    )
    .filter(
      entity =>
        entityUtils.isVisible(entity, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
    .filter(entity =>
      popupId
        ? entityUtils.isPopupAscendantOfEntity(
            state.entities[popupId],
            entity,
            state.entities,
          )
        : entityUtils.isNotBelongsToPopups(entity, state.entities),
    )
    .reduce((acc, entity) => {
      const castedSlug = (entity as OldFieldEntityInterface).options
        .slug as string
      const isMandatory = acc[castedSlug] && acc[castedSlug].optional === false

      acc[castedSlug] = {
        value: fields[castedSlug],
        optional: isMandatory
          ? false
          : !!(entity as OldFieldEntityInterface).options.optional,
      }
      return acc
    }, {} as Record<string, { value: string; optional: boolean }>)
}

export const findEntityDescendantsCheckboxes = (
  state: PageState,
  entityId: string,
) => {
  const mandatoryCheckboxes = entityUtils
    .getAllDescendantsByEntityType(
      state.entities,
      entityId,
      EntityTypeEnum.Checkbox,
    )
    .filter(checkbox => !(checkbox as CheckboxInterface).optional)

  const mandatoryExplicitConsents = entityUtils
    .getAllDescendantsByEntityType(
      state.entities,
      entityId,
      structureTypes.EXPLICIT_CONSENT,
    )
    .filter(
      explicitConsent =>
        !(explicitConsent as OldEntityInterface).options?.optional,
    )

  return mandatoryCheckboxes.concat(mandatoryExplicitConsents) as (
    | OldEntityInterface
    | CheckboxInterface
  )[]
}

function getEntitiesByType(pageState: PageState, type: EntityType) {
  return Object.values(pageState.entities).filter(
    entity => entity.type === type,
  )
}

export const getVisibleMandatoryOptInRecaptchas = (
  state: PageState,
  isDesktop: boolean,
  popupId?: string | null,
) => {
  return Object.values(state.entities)
    .filter(entity => entity.type === EntityTypeEnum.OptInRecaptcha)
    .filter(
      entity =>
        entityUtils.isVisible(entity, isDesktop) &&
        entityUtils.hasAllVisibleAscendants(entity, state.entities, isDesktop),
    )
    .filter(entity =>
      popupId
        ? entityUtils.isPopupAscendantOfEntity(
            state.entities[popupId],
            entity,
            state.entities,
          )
        : entityUtils.isNotBelongsToPopups(entity, state.entities),
    )
}

export const getEntityDescendantsContactUsElements = (
  state: PageState,
  entityId: string,
) => {
  const contactUsFields = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.ContactUsField,
  )
  const oldContactUsRecaptcha = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.Recaptcha,
  )
  const contactUsRecaptcha = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.ContactUsRecaptcha,
  )
  const contactUsAttachments = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.Attachments,
  )
  const contactUsButton = entityUtils.getAllDescendantsByEntityType(
    state.entities,
    entityId,
    EntityTypeEnum.Button,
  )
  return contactUsFields.concat(
    oldContactUsRecaptcha,
    contactUsRecaptcha,
    contactUsAttachments,
    contactUsButton,
  )
}

export function getIsPageTemplate(state: PageState) {
  return state.isTemplate
}

const selectors = {
  getPageId,
  getLocale,
  getGlobalLinkColor,
  getGlobalTextColor,
  getGlobalTextFontSize,
  getGlobalTextFontFamily,
  getGlobalMobileTextFontSize,
  getGlobalTextLineHeight,
  getGlobalMobileTextLineHeight,
  getOrderedByPositionHeadings,
  getGlobalHeadingColor,
  getGlobalHeadingFontFamily,
  getGlobalFontProperties,
  getPageType,
  hasAllVisibleAscendants,
  getRootEntity,
  getSeo,
  getAscendantPopupId,
  getVisibleCheckboxes,
  getVisibleProductElement,
  getVisibleTwoStepPaymentFormOptInStep,
  getVisibleOptInFieldProperties,
  getVisibleMandatoryCheckboxes,
  findEntityDescendantsCheckboxes,
  getEntitiesByType,
  getVisibleMandatoryOptInRecaptchas,
  getEntityDescendantsContactUsElements,
  getIsPageTemplate,
  getChildrenEntities,
}

export default selectors
