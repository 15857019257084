import React from 'react'
import * as TwoStepPaymentForm from 'common/components/entities/TwoStepPaymentForm'
import usePaypalPaymentHandler from 'publisher/hooks/usePaypalPaymentHandler'
import useManagement, {
  selectors as managementSelectors,
} from '../../../hooks/useManagement'
import FieldErrors from '../../FieldErrors'
import { TwoStepPaymentButtonProps } from './TwoStepPaymentButtonProps'

export default function TwoStepPaypalPaymentButton({
  entity,
  styles,
  mobileStyles,
}: TwoStepPaymentButtonProps) {
  const { errors, isLoading, handleSubmit, isScriptLoading } =
    usePaypalPaymentHandler(entity)

  const isPreview = useManagement(managementSelectors.isPreviewMode)
  return (
    <React.Fragment>
      <TwoStepPaymentForm.Button
        onClick={handleSubmit}
        styles={styles}
        mobileStyles={mobileStyles}
        text={entity.options.buttonText}
        subText={entity.options.buttonSubText}
        disabled={isLoading || isPreview || isScriptLoading}
      />
      <FieldErrors errors={errors} />
    </React.Fragment>
  )
}
