import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputRadioUi,
  PaymentMethodLabelUi,
  PaymentMethodSwitcherCreditCardUi,
  PaymentMethodSwitcherItemImageUi,
  PaymentMethodSwitcherItemUi,
} from 'common/components/entities/PaymentMethods'
import {
  creditCardMethodTypes,
  getCreditCardPaymentMethodTitle,
} from 'common/components/entities/PaymentMethods/utils/creaditCardsUtils'
import {
  paymentMethodsImages,
  paymentMethodsImagesSize,
  paymentMethodsNames,
} from 'common/constants/paymentMethodTypes'
import PaymentMethodDetails from './PaymentMethodDetails'

function PaymentMethodSwitcher({
  paymentMethods,
  changeMethod,
  activeMethod,
  fontSize,
  mobileFontSize,
  lineHeight,
  mobileLineHeight,
  fontWeight,
  fontStyle,
  fontFamily,
  mobileFontStyle,
  mobileFontWeight,
  mobileFontFamily,
  color,
  mobileColor,
}) {
  const { t } = useTranslation(['common', 'publisher'])

  return (
    <React.Fragment>
      {paymentMethods
        .filter(method => creditCardMethodTypes.includes(method))
        .map(method => (
          <React.Fragment key={method}>
            <PaymentMethodSwitcherItemUi
              onClick={() => changeMethod(method)}
              active={activeMethod === method}
              data-test-id={`payment-method-${method}`}
            >
              <InputRadioUi checked={activeMethod === method} />
              <PaymentMethodLabelUi
                fontSize={fontSize}
                mobileFontSize={mobileFontSize}
                lineHeight={lineHeight}
                mobileLineHeight={mobileLineHeight}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                mobileFontStyle={mobileFontStyle}
                mobileFontWeight={mobileFontWeight}
                mobileFontFamily={mobileFontFamily}
                color={color}
                mobileColor={mobileColor}
              >
                {getCreditCardPaymentMethodTitle(method, t)}
              </PaymentMethodLabelUi>
              <PaymentMethodSwitcherCreditCardUi />
            </PaymentMethodSwitcherItemUi>
            <PaymentMethodDetails
              paymentMethod={method}
              isActive={activeMethod === method}
            />
          </React.Fragment>
        ))}
      {paymentMethods
        .filter(pm => creditCardMethodTypes.includes(pm) === false)
        .map(method => (
          <React.Fragment key={method}>
            <PaymentMethodSwitcherItemUi
              key={'method' + method}
              onClick={() => changeMethod(method)}
              active={activeMethod === method}
              data-test-id={`payment-method-${method}`}
            >
              <InputRadioUi checked={activeMethod === method} />
              <PaymentMethodLabelUi
                fontSize={fontSize}
                mobileFontSize={mobileFontSize}
                lineHeight={lineHeight}
                mobileLineHeight={mobileLineHeight}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                mobileFontStyle={mobileFontStyle}
                mobileFontWeight={mobileFontWeight}
                mobileFontFamily={mobileFontFamily}
                color={color}
                mobileColor={mobileColor}
              >
                {paymentMethodsNames[method]}
              </PaymentMethodLabelUi>
              {paymentMethodsImages[method] && (
                <PaymentMethodSwitcherItemImageUi
                  src={paymentMethodsImages[method]}
                  width={paymentMethodsImagesSize[method].width}
                  height={paymentMethodsImagesSize[method].height}
                />
              )}
            </PaymentMethodSwitcherItemUi>
            <PaymentMethodDetails
              paymentMethod={method}
              isActive={method === activeMethod}
            />
          </React.Fragment>
        ))}
    </React.Fragment>
  )
}

PaymentMethodSwitcher.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeMethod: PropTypes.bool.isRequired,
  changeMethod: PropTypes.func,
  fontSize: PropTypes.number,
  mobileFontSize: PropTypes.number,
  lineHeight: PropTypes.string,
  mobileLineHeight: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  fontStyle: PropTypes.string,
  mobileFontFamily: PropTypes.string,
  mobileFontWeight: PropTypes.string,
  mobileFontStyle: PropTypes.string,
  color: PropTypes.string,
  mobileColor: PropTypes.string,
}

export default PaymentMethodSwitcher
