export const localeValues = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  it: 'it',
  pt: 'pt',
  de: 'de',
  nl: 'nl',
  ru: 'ru',
  jp: 'jp',
  ar: 'ar',
  tr: 'tr',
  zh: 'zh',
  sv: 'sv',
  ro: 'ro',
  cs: 'cs',
  hu: 'hu',
  sk: 'sk',
  dk: 'dk',
  id: 'id',
  pl: 'pl',
  el: 'el',
  sr: 'sr',
  hi: 'hi',
  nb: 'nb',
  th: 'th',
  sl: 'sl',
  ua: 'ua',
  sq: 'sq',
}

export const localeNames = {
  [localeValues.en]: 'locale_name.english',
  [localeValues.fr]: 'locale_name.french',
  [localeValues.es]: 'locale_name.spanish',
  [localeValues.it]: 'locale_name.italian',
  [localeValues.pt]: 'locale_name.portuguese',
  [localeValues.de]: 'locale_name.german',
  [localeValues.nl]: 'locale_name.dutch',
  [localeValues.ru]: 'locale_name.russian',
  [localeValues.jp]: 'locale_name.japanese',
  [localeValues.ar]: 'locale_name.arabic',
  [localeValues.tr]: 'locale_name.turkish',
  [localeValues.zh]: 'locale_name.chinese',
  [localeValues.sv]: 'locale_name.swedish',
  [localeValues.ro]: 'locale_name.romanian',
  [localeValues.cs]: 'locale_name.czech',
  [localeValues.hu]: 'locale_name.hungarian',
  [localeValues.sk]: 'locale_name.slovak',
  [localeValues.dk]: 'locale_name.danish',
  [localeValues.id]: 'locale_name.indonesian',
  [localeValues.pl]: 'locale_name.polish',
  [localeValues.el]: 'locale_name.greek',
  [localeValues.sr]: 'locale_name.serbian',
  [localeValues.hi]: 'locale_name.hindi',
  [localeValues.nb]: 'locale_name.norwegian',
  [localeValues.th]: 'locale_name.thai',
  [localeValues.sl]: 'locale_name.slovene',
  [localeValues.ua]: 'locale_name.ukrainian',
  [localeValues.sq]: 'locale_name.albania',
}
