import styled, { css } from 'styled-components'
import { AlignType, PaddingType } from 'common/types/styleTypes'
import {
  backgroundColorCss,
  textCss,
  mobileBackgroundColorCss,
  mobileTextCss,
  mobilePaddingsCss,
  paddingsCss,
} from 'common/utils/styleUtilsNew'

export interface TextUiProps {
  fontSize?: number
  mobileFontSize?: number
  letterSpacing?: number
  mobileLetterSpacing?: number
  fontFamily?: string
  fontStyle?: string
  fontWeight?: string
  mobileFontFamily?: string
  mobileFontStyle?: string
  mobileFontWeight?: string
  lineHeight?: number
  mobileLineHeight?: number
  color?: string
  linkColor?: string
  textAlign: AlignType
  backgroundColor?: string
  mobileBackgroundColor?: string
  mobileTextAlign?: AlignType
  padding?: PaddingType
  mobilePadding?: PaddingType
  enableHeadings?: boolean
}

// the "inherit" value breaks the new logic where we pass the global headline font-family to the entity
export function calculateHeadlineFontFamily(
  entityFontFamily?: string,
  globalHeadlineFontFamily?: string,
  globalFontFamily?: string,
) {
  let fontFamily = entityFontFamily
  if (entityFontFamily === 'inherit' || !entityFontFamily) {
    if (globalHeadlineFontFamily) {
      fontFamily = globalHeadlineFontFamily
    } else if (globalFontFamily) {
      fontFamily = globalFontFamily
    }
  }
  return fontFamily
}

const TextUi = styled.div<TextUiProps>`
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out;
  flex-basis: inherit;
  ${textCss};
  ${paddingsCss};
  ${backgroundColorCss}
  ${p =>
    p.linkColor &&
    css`
      & a {
        color: ${p.linkColor};
      }
      & a > span {
        color: ${p.linkColor};
      }
    `}
  & a {
    &:hover {
      box-shadow: inset 0 -1px 0;
    }
  }
  & a > span {
    &:hover {
      box-shadow: inset 0 -1px 0;
    }
  }
  ${p =>
    p.enableHeadings &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        ${textCss};
      }
    `}

  ${p => p.theme.phone} {
    ${mobilePaddingsCss};
    ${mobileTextCss};
    ${mobileBackgroundColorCss}
    ${p =>
      p.enableHeadings &&
      css`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          ${mobileTextCss};
        }
      `}
  }
`

export default TextUi
