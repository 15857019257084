import { BadRequest } from 'common/errors/BadRequest'
import { Cancelled } from 'common/errors/Cancelled'
import { GatewayError } from 'common/errors/GatewayError'
import { InternalError } from 'common/errors/InternalError'
import { MissingContentLengthError } from 'common/errors/MissingContentLengthError'
import { NetworkError } from 'common/errors/NetworkError'
import { NotFound } from 'common/errors/NotFound'
import { ServiceUnavailable } from 'common/errors/ServiceUnavailable'
import { getSaParameterFromUrl } from '../../publisher/utils/getSaParameterFromUrl'
import { AccessDenied } from '../errors/AccessDenied'

const sa = getSaParameterFromUrl()

export const createRequestInterceptor = axiosInstance => {
  axiosInstance.interceptors.request.use(
    function (config) {
      // we need to pass the "sa" parameter instead of using a cookie
      // see https://app.shortcut.com/systeme/story/25755/improve-affiliate-program-logic-for-aurelien-account
      if (
        config.method === 'post' &&
        config.url.includes('buy-main-offer') &&
        sa
      ) {
        const url = new URL(config.url, window.location.origin)
        url.searchParams.append('sa', sa)
        config.url = url.toString()
      }
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    },
  )
}

export const createResponseInterceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        if (error.response.status === 400) {
          throw new BadRequest(error.response)
        } else if (error.response.status === 411) {
          throw new MissingContentLengthError(error.response)
        } else if (error.response.status === 404) {
          throw new NotFound()
        } else if (
          error.response.status === 403 ||
          error.response.status === 401
        ) {
          const loginWindow = window.open('', 'preview')
          if (loginWindow) {
            loginWindow.location.href = error.response.data.location
          } else {
            window.open(error.response.data.location, '_blank')
          }
          throw new AccessDenied()
        } else if (
          error.response.status === 502 ||
          error.response.status === 504
        ) {
          throw new GatewayError()
        } else if (error.response.status === 503) {
          throw new ServiceUnavailable()
        } else {
          throw new InternalError(
            error.response.data
              ? error.response.data.message
              : error.response.data,
          )
        }
      } else if (error.constructor.name === 'Cancel') {
        throw new Cancelled()
      } else {
        throw new NetworkError()
      }
    },
  )
}
