import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { getVat } from 'common/utils/taxCalculator'
import { useOptIn, usePayment } from 'publisher/store'
import {
  getCustomerCountry,
  getFieldValueBySlug,
} from 'publisher/store/optIn/optInSelectors'
import {
  getOfferOwnerCountry,
  getProduct,
} from 'publisher/store/payment/paymentSelectors'

export function useVat() {
  const offerOwnerCountry = usePayment(getOfferOwnerCountry)
  const product = usePayment(getProduct)
  const customerCountry = useOptIn(
    state =>
      getFieldValueBySlug(state, FieldSlugEnum.Country) ||
      getCustomerCountry(state),
  )
  const taxNumber = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.TaxNumber),
  )

  return getVat(offerOwnerCountry, customerCountry, taxNumber, product)
}
