import PropTypes from 'prop-types'
import { Provider as RollbarProvider } from '@rollbar/react'
import React, { createContext } from 'react'
import { Provider } from 'react-redux'
import Page from 'publisher/components/Page'

export const CreateEntitiesContext = createContext(null)
export const rollbarConfig = {
  enabled: process.env.NODE_ENV !== 'development',
  accessToken: process.env.ROLLBAR_PUBLISHER_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false,
  autoInstrument: true,
  payload: {
    host: process.env.SYSTEME_IO_HOST,
    environment: process.env.APP_ENV,
  },
}

const App = ({ store, createEntityElement, createPage }) => (
  <CreateEntitiesContext.Provider value={createEntityElement}>
    <RollbarProvider config={rollbarConfig}>
      <Provider store={store}>
        <Page
          createEntityElement={createEntityElement}
          createPage={createPage}
        />
      </Provider>
    </RollbarProvider>
  </CreateEntitiesContext.Provider>
)

App.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  createPage: PropTypes.func.isRequired,
  createEntityElement: PropTypes.func.isRequired,
}

export default App
