import axios from 'axios'
import { BadRequest } from 'common/errors'

export function buyMainOffer(pageId, purchaseProcessId, body) {
  return axios.post(
    `/secure/stripe/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsell(pageId, purchaseProcessId, body) {
  return axios.post(
    `/secure/stripe/${pageId}/buy-upsell/${purchaseProcessId}`,
    body,
  )
}

export function confirmPaymentIntent(pageId, purchaseProcessId) {
  return axios.get(
    `/secure/stripe/${pageId}/payment/confirm/${purchaseProcessId}`,
  )
}

export const recursiveHandlePayment = async (
  pageId,
  purchaseProcessId,
  // bump,
  clientSecret,
  handlePayment,
  handleErrors,
) => {
  const { error } = await handlePayment(clientSecret)
  if (!error) {
    // The card action has been handled
    // The PaymentIntent can be confirmed again on the server
    try {
      const { data } = await confirmPaymentIntent(pageId, purchaseProcessId)
      // redirect to upSell or thank you page
      if (data.redirect) {
        window.location.href = data.redirect
      } else if (data.clientSecret) {
        recursiveHandlePayment(
          pageId,
          purchaseProcessId,
          // bump,
          clientSecret,
          handlePayment,
          handleErrors,
        )
      }
    } catch (error) {
      if (error instanceof BadRequest) {
        handleErrors(error.response.data.errors)
      }
    }
  } else {
    handleErrors([error.message])
  }
}
