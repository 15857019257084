import React from 'react'
import * as TwoStepPaymentForm from '../../../../common/components/entities/TwoStepPaymentForm'
import useManagement, {
  selectors as managementSelectors,
} from '../../../hooks/useManagement'
import useMercadoPagoPaymentHandler from '../../../hooks/useMercadoPagoPaymentHandler'
import FieldErrors from '../../FieldErrors'
import { TwoStepPaymentButtonProps } from './TwoStepPaymentButtonProps'

function TwoStepMercadoPagoCreditCardPaymentButton({
  entity,
  styles,
  mobileStyles,
}: TwoStepPaymentButtonProps) {
  const { errors, isLoading, handleSubmit } =
    useMercadoPagoPaymentHandler(entity)
  const isPreview = useManagement(managementSelectors.isPreviewMode)

  return (
    <React.Fragment>
      <TwoStepPaymentForm.Button
        onClick={handleSubmit}
        styles={styles}
        mobileStyles={mobileStyles}
        text={entity.options.buttonText}
        subText={entity.options.buttonSubText}
        disabled={isLoading || isPreview}
      />
      <FieldErrors errors={errors} />
    </React.Fragment>
  )
}

export default TwoStepMercadoPagoCreditCardPaymentButton
