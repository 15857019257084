import { getPriceWithCoupon } from 'common/utils/priceCalculator'
import { usePayment } from 'publisher/store'
import {
  getProduct,
  getProductActiveVariant,
  getCheckedCoupon,
  getProductQuantity,
  isOnlyPersonalAvailable,
} from 'publisher/store/payment/paymentSelectors'
import { useVat } from './useVat'

export function useFinalPhysicalProductPrice() {
  const product = usePayment(getProduct)
  const activeVariant = usePayment(getProductActiveVariant)
  const coupon = usePayment(getCheckedCoupon)
  const productQuantity = usePayment(getProductQuantity)
  const isVatNotChargeable = usePayment(isOnlyPersonalAvailable)
  const vat = useVat()

  if (!product) {
    return null
  }

  const selectedProductPrice = activeVariant?.price
    ? activeVariant?.price
    : product?.id
    ? product.price
    : 0

  return getPriceWithCoupon(
    selectedProductPrice,
    coupon,
    productQuantity,
    isVatNotChargeable ? 0 : vat,
  )
}
