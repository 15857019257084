import { useTranslation } from 'react-i18next'
import React from 'react'
import { Coupon as CouponType } from 'common/types/CouponType'
import { PricePlan as PricePlanType } from 'common/types/OfferInterface'
import { ReadablePricePlanAmount } from './PricePlan'
import PricePlanAmountUi from './ui/PricePlanAmountUi'
import PricePlanDescriptionUi from './ui/PricePlanDescriptionUi'
import PricePlanItemUi from './ui/PricePlanItemUi'

type TotalProps = {
  plan: PricePlanType
  tax: number
  coupon?: CouponType
  quantity: number
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

function Total({
  plan,
  tax,
  coupon,
  quantity,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: TotalProps) {
  const { t } = useTranslation(['common', 'publisher'])
  return (
    <PricePlanItemUi>
      <PricePlanDescriptionUi
        fontSize={descriptionFontSize}
        mobileFontSize={mobileDescriptionFontSize}
        fontStyle={descriptionFontStyle}
        mobileFontStyle={mobileDescriptionFontStyle}
        fontWeight={descriptionFontWeight}
        mobileFontWeight={mobileDescriptionFontWeight}
        fontFamily={descriptionFontFamily}
        mobileFontFamily={mobileDescriptionFontFamily}
        lineHeight={descriptionLineHeight}
        mobileLineHeight={mobileDescriptionLineHeight}
        color={descriptionColor}
        mobileColor={mobileDescriptionColor}
        data-test-id={`offer-price-bump-total-${plan.id}`}
      >
        {t('components.offer_price.total')}
      </PricePlanDescriptionUi>
      <PricePlanAmountUi
        fontSize={amountFontSize}
        mobileFontSize={mobileAmountFontSize}
        fontStyle={amountFontStyle}
        mobileFontStyle={mobileAmountFontStyle}
        fontWeight={amountFontWeight}
        mobileFontWeight={mobileAmountFontWeight}
        fontFamily={amountFontFamily}
        mobileFontFamily={mobileAmountFontFamily}
        lineHeight={amountLineHeight}
        mobileLineHeight={mobileAmountLineHeight}
        color={amountColor}
        mobileColor={mobileAmountColor}
      >
        <ReadablePricePlanAmount
          plan={plan}
          tax={tax}
          coupon={coupon}
          quantity={quantity}
          locale={locale}
        />
      </PricePlanAmountUi>
    </PricePlanItemUi>
  )
}

export default Total
