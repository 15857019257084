import { ProductInterface } from 'common/types/entities/ProductInterface'
import { getCountryVat, isFromEU } from '../constants/countryVatList'

export function getVat(
  ownerCountry: string,
  customerCountry?: string,
  taxNumber?: string,
  product?: ProductInterface | null,
) {
  if (!customerCountry) {
    return 0
  }

  // customers are from Martinique, Guadeloupe and Reunion
  if (['MQ', 'GP', 'RE'].includes(customerCountry)) {
    if (product) {
      return 0
    }

    //seller from Martinique, Guadeloupe and Reunion or France
    if (['MQ', 'GP', 'RE', 'FR'].includes(ownerCountry)) {
      return 8.5
    }

    return 0
  }

  // sellers are from Martinique, Guadeloupe and Reunion
  if (['MQ', 'GP', 'RE'].includes(ownerCountry)) {
    if (customerCountry === 'FR') {
      return 8.5
    }

    return 0
  }

  //customers or sellers are from French Guiana and Mayotte
  if (
    ['GF', 'YT'].includes(customerCountry) ||
    ['GF', 'YT'].includes(ownerCountry)
  ) {
    return 0
  }

  //New Zealand
  if (customerCountry === 'NZ' && ownerCountry === 'NZ') {
    if (product) {
      return product.tax
    }
    return 15
  }

  //Great Britain
  if (customerCountry === 'GB' && ownerCountry === 'GB') {
    if (product) {
      return product.tax
    }
    return 20
  }

  // EU customer without VAT
  if (isFromEU(customerCountry) && !taxNumber) {
    if (product) {
      return product.tax
    }
    return getCountryVat(customerCountry) as number
  }

  // EU customer with VAT and seller from the same country
  if (
    isFromEU(customerCountry) &&
    taxNumber &&
    ownerCountry == taxNumber.slice(0, 2).toUpperCase()
  ) {
    if (product) {
      return product.tax
    }
    return getCountryVat(customerCountry) as number
  }

  if (product) {
    return product.tax
  }

  return 0
}
