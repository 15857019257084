export enum FieldSlugEnum {
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirm_password',
  FirstName = 'first_name',
  Subject = 'subject',
  Surname = 'surname',
  PhoneNumber = 'phone_number',
  Country = 'country',
  StreetAddress = 'street_address',
  City = 'city',
  Postcode = 'postcode',
  CompanyName = 'company_name',
  TaxNumber = 'tax_number',
  State = 'state',
  DlocalDocumentNumber = 'dlocal_document_number',
  Message = 'message',
  Recaptcha = 'recaptcha',
  Attachments = 'attachments',
}
