import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { NetworkError } from 'common/errors'
import * as api from 'publisher/api/lecture'
import useLecture, { selectors } from 'publisher/hooks/useLecture'
import BaseButton from '../BaseButton'
import { TEST_ID } from './SendFormButton'
import { useRollbar } from '@rollbar/react'

function CompleteAndRedirectButton({ entity }) {
  const rollbar = useRollbar()
  const { styles, mobileStyles, options, mobileOptions } = entity
  const lectureId = useLecture(selectors.getLectureId)
  const [loading, setLoading] = useState(false)

  async function completeAndRedirect() {
    try {
      setLoading(true)
      const { data } = await api.completeAndRedirect(lectureId)
      if (data.redirect) {
        window.open(data.redirect, '_parent')
      }
    } catch (e) {
      if (e instanceof NetworkError) {
        setLoading(false)
        // do nothing
      } else {
        rollbar.error('Lecture complete failed', e)
      }
    }
  }

  return (
    <BaseButton
      dataTestId={TEST_ID}
      handleClick={completeAndRedirect}
      disabled={loading}
      styles={styles}
      mobileStyles={mobileStyles}
      attrId={options.attrId}
      text={options.text}
      subText={options.subText}
      subTextColor={options.subTextColor}
      subTextFontSize={options.subTextFontSize}
      subTextFontWeight={options.subTextFontWeight}
      subTextFontStyle={options.subTextFontStyle}
      subTextFontFamily={options.subTextFontFamily}
      mobileSubTextColor={mobileOptions.subTextColor}
      mobileSubTextFontSize={mobileOptions.subTextFontSize}
      mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
      mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
      mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
      iconClassNameBefore={entity.options.iconClassNameBefore}
      iconClassNameAfter={entity.options.iconClassNameAfter}
      borderType={options.borderType}
      mobileBorderType={mobileOptions.borderType}
      appearance={options.appearance}
      hoverStyles={options?.hover}
      hoverMobileStyles={mobileOptions?.hover}
    />
  )
}

CompleteAndRedirectButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      redirectionType: PropTypes.string.isRequired,
      urlRedirect: PropTypes.string,
      attrId: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      subText: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
      borderType: PropTypes.string.isRequired,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      hover: PropTypes.shape({
        textColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        subTextColor: PropTypes.string,
        transform: PropTypes.string,
        offset: PropTypes.number,
      }),
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default CompleteAndRedirectButton
