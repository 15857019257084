import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'
import Meta from '../components/core/Meta'
import { DLocalProvider } from '../context/DlocalContext'
import { XenditProvider } from '../context/XenditContext'
import { MercadoPagoProvider } from '../context/mercadoPagoContext'
import usePayment, { selectors as paymentSelectors } from '../hooks/usePayment'
import usePhysicalProductsFromQueryString from '../hooks/usePhysicalProductsFromQueryString'
import OnExitPopup from './components/OnExitPopup'
import OnloadPopups from './components/OnloadPopups'
import TriggeredPopup from './components/TriggeredPopup'
import PageUi from './ui/PageUi'

function PaymentPage({ children }) {
  const globalTextFontProperties = usePage(
    pageSelectors.getGlobalFontProperties,
  )
  const locale = usePage(pageSelectors.getLocale)
  const stripeAccount = usePayment(paymentSelectors.getStripeAccountId)
  usePhysicalProductsFromQueryString()
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    const stripePromise = loadStripe(process.env.STRIPE_KEY, {
      stripeAccount,
    })
    setStripe(stripePromise)
  }, [stripeAccount])

  return (
    <Elements stripe={stripe} options={{ locale }}>
      <DLocalProvider>
        <MercadoPagoProvider>
          <XenditProvider>
            <PageUi
              textFontFamily={wrapFontFamily(
                globalTextFontProperties.fontFamily,
              )}
              textFontWeight={globalTextFontProperties.fontWeight}
              textFontStyle={globalTextFontProperties.fontStyle}
            >
              <Meta />
              {children}
              <TriggeredPopup />
              <OnExitPopup />
              <OnloadPopups />
            </PageUi>
          </XenditProvider>
        </MercadoPagoProvider>
      </DLocalProvider>
    </Elements>
  )
}

PaymentPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaymentPage
