import { StripeIdealBankElement } from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { useRollbar } from '@rollbar/react'
import {
  IdealBankElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { IntentTypeEnum } from 'publisher/enums/IntentTypeEnum'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import { OldEntityInterface } from '../../common/types/entities/OldEntityInterface'
import { optInFail } from '../actions/optInActions'
import * as api from '../api/stripe'
import { confirmPaymentIntent } from '../api/stripe'
import usePage, { selectors as pageSelectors } from './usePage'
import usePaymentSubmit from './usePaymentSubmit'

function getStripeConfirmHandler(stripe: Stripe, type: IntentTypeEnum) {
  return type === IntentTypeEnum.setup
    ? stripe.confirmIdealSetup
    : stripe.confirmIdealPayment
}

export default function useStripeCardPaymentHandler(
  entity: OldEntityInterface,
) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const stripe = useStripe()
  const elements = useElements()
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    await submit(async body => {
      try {
        const { data } = await api.buyMainOffer(pageId, purchaseProcessId, {
          payment_form: { ...body },
        })
        const { intentSecret, intentType, redirect } = data

        if (intentSecret) {
          const stripeConfirmHandler = getStripeConfirmHandler(
            stripe,
            intentType,
          )

          const { error } = await stripeConfirmHandler(intentSecret, {
            payment_method: {
              ideal: elements.getElement(
                IdealBankElement,
              ) as StripeIdealBankElement,
              billing_details: {
                name: body.customer.fields.first_name,
                email: body.customer.fields.email,
              },
            },
            return_url: redirect,
          })

          if (!error) {
            const { data } = await confirmPaymentIntent(
              pageId,
              purchaseProcessId,
            )
            // redirect to upSell or thank you page
            if (data.redirect) {
              window.location.href = data.redirect
            }
          } else {
            setErrors([error.message as string])
          }
        } else {
          // redirect to upSell or thank you page
          window.location.href = redirect
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.captureEvent(body, 'debug')
          rollbar.error('Stripe Ideal payment failed', error as Error)
        }
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}
